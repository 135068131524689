import i18n from "i18next";
import { ChangeDateFormat } from "../../Common/Helpers/ChangeDateFormat";

class ResponseSalarySheetModel {
    constructor({
                    id = '',
                    visualId = '',
                    userName = '',
                    orgName = '',
                    date = '',
                    fileId = '',
                    status = '',
                    createdAt = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.userName = userName;
        this.orgName = orgName;
        this.date = date;
        this.fileId = fileId;
        this.status = status;
        this.createdAt = createdAt;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const userName = i18n.t('modelLabel.name');
        const orgName = i18n.t('modelLabel.org');
        const date = i18n.t('modelLabel.date');
        const status = i18n.t('modelLabel.status');
        const createdAt = i18n.t('modelLabel.createdAt');

        const attributes = {
            id :{
                'label': 'ID',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            userName :{
                'label': userName,
                'sortable': true,
                'sortField': 'userName',
                'value': (item) => item.userName || "",
            },
            orgName :{
                'label': orgName,
                'sortable': true,
                'sortField': 'orgName',
                'value': (item) => item.orgName || "",
            },
            date :{
                'label': date,
                'sortable': true,
                'sortField': 'date',
                'value': (item) => item.date || "",
            },
            fileId :{
                'label': orgName,
                'sortable': true,
                'sortField': 'fileId',
                'value': (item) => item.fileId || "",
            },
            status :{
                'label': status,
                'sortable': true,
                'sortField': 'status',
                'value': (item) => item.status || "",
            },
            createdAt :{
                'label': createdAt,
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : "" || "",
                'type': 'dateTime',
            },
        }

        return attributes;
    }
}

export default ResponseSalarySheetModel;