import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {CustomPhoneInput} from "./CustomPhoneInput";
import {useDropzone} from "react-dropzone";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import {CustomButton} from "../Button/CustomButton";
import {API_URL} from "../../../config";
import useApiCallService from "../../Helpers/ApiCall";
import {CustomModal} from "../Modal/CustomModal";
import {useTranslation} from "react-i18next";
import {NumberInput, NumberInputInternal} from "./Fields/NumberInput";
import {CustomDateLocale} from "../../Helpers/CustomDateLocale";
/*

// EXAMPLE MODEL FOR USE CUSTOMINPUT

class FormModel {
    constructor({
                    name = '',
                    lastName = '',
                    company = '',
                    phone = '',
                    date = '',
                    time = '',
                    dateTime ='',
                    dateRangePicker = '',
                    comment ='', //test para textarea
                    file = '',
                    color = '',
                    checkbox = '',
                    switchButton ='',
                    radio = '',
                    search = '',
                    password = '',
                    email = '',
                    range = '',

                } = {}) {
        this.name = name;
        this.lastName = lastName;
        this.company = company;
        this.phone = phone;
        this.date = date;
        this.time = time;
        this.dateTime = time;
        this.dateRangePicker = dateRangePicker;
        this.comment = comment;
        this.file = file;
        this.color = color;
        this.checkbox = checkbox;
        this.switchButton = switchButton;
        this.radio = radio;
        this.search = search;
        this.password = password;
        this.email = email;
        this.range = range;


    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

    //DEFINE NAME OPTINOS FOR SELECT

        let optionsAccounting = [];
        let optionsCostBearer = [];
            if (optionsData) {
                optionsAccounting = optionsData.accounting;
                optionsCostBearer = optionsData.costBearer;
            }



        const completeSchema = yup.object().shape({
            name: string().required('Field required').min(3, 'The name must be at least 3 characters'),
            lastName: string().required('Field required').min(5, 'The name must be at least 5 characters'),
            company:string().required('Select an option').notOneOf([''], 'Please select a valid option'),
            phone: string().test('phone', 'invalid format phone', function (value) {
                        if (!value || value.trim() === '') return true;  // The field is not required but if you write it, it has to have that structure
                        return /^\+\d{1,15}$/.test(value)}),
            date: string().required('Field required'),
            time: string().required('Field required'),
            dateTime: string().required('Field required'),
            comment: string().required('Field required'),
            file: string().required('Field required'),
            color: string().required('Field required'),
            checkbox: string().required('Field required'),
            switchButton: string().required('Field required'),
            radio:string().required('Select an option').notOneOf([''], 'Please select a valid option'),
            password: string().required('Password is required'),
            //validation for email required
            email: string().required('Field required')
                .max(45, 'Max. 45 characters')
                .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email'),
            //validation for email not required
            emailNotRequired: string()
                .test('email', 'Email format is incorrect', function (value) {
                    if (!value || value.trim() === '') return true;  // // The field is not required but if you write it, it has to have that structure
                    return /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(value)})

        });

        const attributes = {
            name :{
                'label': 'Name',
                'form': {
                    'type': 'text',
                    'inputType': 'inline', // inside     ,This is to change the style of the input with several classes that are default
                    'inputIcon': 'bx bx-user',// This is for the inside style icon
                    'colSizeInput': 6,// Size you want for the input columns, the default is 12
                    'inputSize':'long', //long ,small
                    'placeholder': 'Enter your name',
                    'validation': completeSchema.Fields.name,
                },
                'sortable': false,
                'filter': false,
                'id':'name',
                'name':'testName',
                'value': (item) => item.name || "",
            },
            lastName :{
                'label': 'Last Name',
                'form': {
                    'type': 'text',
                    'inputType': 'inside', //inline
                    'inputIcon': 'bx bx-user',
                    'colSizeInput': 6,
                    'inputSize':'long',
                    'placeholder': 'Enter your last name',
                    'validation':completeSchema.Fields.lastName,
                },
                'sortable': false,
                'filter': false,
                'id':'lastName',
                'name':'lastName',
                'value': (item) => item.lastName || "",
            },
            company:{
                'label': 'Company',
                'form': {
                    'type': 'select',
                    'inputType': 'inline',
                    'inputIcon': 'bx bx-user',
                    'colSizeInput': 6,
                    'options': optionsAccounting,
                    'default':'',
                    'validation': completeSchema.Fields.company,
                },
                'sortField': false,
                'filter': { 'type': 'enum', 'options': companyTypes },
                'id':'date',
                'name':'company',
                'value': (item) => {
                    switch (item.company) {
                        case 'company':
                            return 'Company';
                        case 'freelance':
                            return 'Freelance';
                        default:
                            return 'not set';
                    }
                }
            },
            phone :{
                'label': 'Phone',
                'form': {
                    'type': 'phone',
                    //'inputType': 'inside',
                    //'inputIcon': 'bx bx-user',
                    'colSizeInput': 6,
                    'inputSize':'small', //long ,small
                    'placeholder': 'Enter your phone',
                    'validation': completeSchema.Fields.phone,
                },
                'sortable': false,
                'filter': false,
                'id':'phone',
                'name':'phone',
                'value': (item) => item.phone || "",
            },
            date :{
                'label': 'Date',
                'form': {
                    'type': 'date',
                    //'inputType': 'inside',
                    //'inputIcon': 'bx bx-time-five',
                    'colSizeInput': 6,
                    'inputSize':'small', //long ,small
                    'placeholder': 'Enter your date',
                    'validation': completeSchema.Fields.date,
                },
                'sortable': false,
                'filter': false,
                'id':'date',
                'name':'date',
                'value': (item) => item.date || "not set",
            },
            time :{
                'label': 'Time',
                'form': {
                    'type': 'time',
                    //'inputType': 'inside',
                    //'inputIcon': 'bx bx-time-five',
                    'colSizeInput': 6,
                    'inputSize':'small', //long ,small
                    'placeholder': 'Enter your time',
                    'validation': completeSchema.Fields.time,
                },
                'sortable': false,
                'filter': false,
                'id':'time',
                'name':'time',
                'value': (item) => item.time || "not set",
            },
            dateTime :{
                'label': 'Time',
                'form': {
                    'type': 'dateTime',
                    //'inputType': 'inside',
                    //'inputIcon': 'bx bx-time-five',
                    'colSizeInput': 6,
                    'inputSize':'small', //long ,small
                    'placeholder': 'Enter your date-time',
                    'validation': completeSchema.Fields.dateTime,
                },
                'sortable': false,
                'filter': false,
                'id':'time',
                'name':'time',
                'value': (item) => item.time || "not set",
            },
            /////////////////////// IMPORTANT : The value you are given is an array, you have to check that if it is an array it saves the value of 0 in "from" and the value of 1 in "to" ///////////////////////
            dateRangePicker :{
                'label': 'Date Range Picker',
                'form': {
                    'type': 'dateRangePicker',
                    'colSizeInput': 6,
                    'validation': true, //This option is only for the dateRangePiker the rest of the Fields use yup
                },
                'sortable': false,
                'filter': false,
                'id':'time',
                'name':'time',
                'value': (item) => item.time || "not set",
            },
            comment :{
                'label': 'Comment',
                'form': {
                    'type': 'textarea',
                    //'inputType': 'inside', //inline
                    'inputIcon': 'bx bx-time-five',
                    'colSizeInput': 6,
                    'placeholder': 'Enter your comment',
                    'rows':5, //textarea height
                    'validation': completeSchema.Fields.comment,
                },
                'sortable': false,
                'filter': false,
                'id':'comment',
                'name':'comment',
                'value': (item) => item.comment || "",
            },
            file :{
                'label': 'File',
                'form': {
                    'type': 'file',
                    'placeholder': 'Drop files here or click to upload.', //inside text, to hide it do not put any placeholder
                    //'icon': 'bx bx-health',
                    //'iconView' : false, // show or hide the drag and drop icon
                   'buttonView' : false, // show or hide internal button
                    'validation': completeSchema.Fields.file,
                },
                'sortable': false,
                'filter': false,
                'id':'file',
                'name':'file',
                'value': (item) => item.file || "not set",
            },
            color :{
                'label': 'Color',
                'form': {
                    'type': 'color',
                    //'inputType': 'inline', // In this type of input there is no inside style
                    'validation': completeSchema.Fields.color,
                },
                'sortable': false,
                'filter': false,
                'id':'color',
                'name':'color',
                'value': (item) => item.color || "not set",
            },
            checkbox :{
                'label': 'Checkbox',
                'form': {
                    'type': 'checkbox',
                    'validation': completeSchema.Fields.checkbox,
                },
                'sortable': false,
                'filter': false,
                'id':'checkbox',
                'name':'checkbox',
                'value': (item) => item.checkbox || "not set",
            },
            switchButton :{
                'label': 'Switch',
                'form': {
                    'type': 'switch',
                    'validation': completeSchema.Fields.switchButton,
                },
                'sortable': false,
                'filter': false,
                'id':'switch',
                'name':'switch',
                'value': (item) => item.switchButton || "not set",
            },
            radio :{
                'label': 'Radio',
                'form': {
                    'type': 'radio',
                    'options': optionsCostBearer,
                    'validation': completeSchema.Fields.radio,
                },
                'sortable': false,
                'filter': false,
                'id':'radio',
                'name':'radio',
                'value': (item) => item.radio || "not set",
            },
            password :{
                'label': 'Password',
                'form': {
                    'type': 'password',
                    'inputType': 'inline', //inline
                    'inputIcon': 'bx bx-lock-alt text-muted',
                    'colSizeInput': 6,
                    'inputSize':'long',
                    'placeholder': 'Enter password',
                    'validation': completeSchema.Fields.password,
                },
                'sortable': false,
                'filter': false,
                'id':'password',
                'name':'password',
                'placeholder': 'Enter password',
                'value': (item) => item.password || "",
            },
            email :{
                'label': 'Email',
                'type': {'type': 'email'},
                'form': {
                    'type': 'email',
                    'inputType': 'inside',
                    'inputIcon': 'bx bx-at text-muted',
                    'colSizeInput': 6,
                    'placeholder': 'Enter email',
                    'validation': completeSchema.Fields.email,
                },
                'sortable': false,
                'filter': false,
                'id':'email',
                'name':'email',
                'value': (item) => item.email || "",
            },
            range :{
                'label': 'Range',
                'form': {
                    'type': 'range',
                    'colSizeInput': 6,
                },
                'sortable': false,
                'filter': false,
                'id':'range',
                'name':'range',
                'value': (item) => item.range || "",
            },

        }
        if (validationSchema === true) {
            return completeSchema;
        }

        if (!filter) {
            return attributes;
        }
        if (typeof filter === "string") {
            return { [filter]: attributes[filter] };
        }
        if (Array.isArray(filter)) {
            const filteredAttributes = {};
            filter.forEach(key => {
                filteredAttributes[key] = attributes[key];
            });
            return filteredAttributes;
        }

    }

}

export default FormModel;

//EXAMPLE USE CUSTOMINPUT

 //FUNCTION CUSTOMCHANGE

    // const handleChange = (e, name) => {
    //     if (e && e.target) {
    //         setOrganizationFormInfo((prevFormData) => ({
    //             ...prevFormData,
    //             [name]: e.target.value,
    //         }));
    //     }
    // };


// ADDRESS FUNCTIONS

    //COUNTER FOR INDEX ADDRESS

    // const [numAddresses, setNumAddresses] = useState(1);
    // const handleAddAddress = () => {
    //     setNumAddresses(prevNumAddresses => prevNumAddresses + 1);
    // };


    // SAVE ADDRESS DATA IN ORGANIZATION MODEL (IS A TEST MODEL, CAN BE OTHER MODEL)

    // const handleChangeAddress = (e, name, index) => {
    //     if (e && e.target) {
    //         setOrganizationFormInfo((prevFormData) => ({
    //             ...prevFormData,
    //             address:
    //                 [
    //                     ...prevFormData.address.slice(0, index),
    //                     {
    //                         ...prevFormData.address[index],
    //                         [name]: e.target.value,
    //                     },
    //                     ...prevFormData.address.slice(1 + index),
    //                 ]
    //         }));
    //     }
    // };


    //COPY LAST ADDRESS IN DE ARRAY (EXAMPLE IN CASE THE COPY BUTTON IS NEEDED)

    // const copyAddress = index => {
    //     if (index > 0) {
    //         const previousAddress = organizationFormInfo.address[index - 1];
    //         setOrganizationFormInfo(prevFormData => ({
    //             ...prevFormData,
    //             address: [
    //                 ...prevFormData.address.slice(0, index),
    //                 { ...previousAddress },
    //                 ...prevFormData.address.slice(index + 1),
    //             ],
    //         }));
    //     }
    // };

//API CALL FOR OPTIONS SELECT IN PAGE
  const { ApiCall } = useApiCallService();
    const [apiResponse, setApiResponse] = useState(null);
    const [apiResponseCost, setApiResponseCost] = useState(null);

    useEffect(() => {
        const handleApiResponse  = (apiResponse) => {
            setApiResponse(apiResponse);
        };
        const handleApiResponseCost  = (apiResponseCost) => {
            setApiResponseCost(apiResponseCost);
        };
        const Test = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/accounting/list",
                };
                await ApiCall(apiCallOptions, handleApiResponse);
            } catch (error) {
                console.log('error', error);
            }
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/costbearer/list",
                };
                await ApiCall(apiCallOptions, handleApiResponseCost);
            } catch (error) {
                console.log('error', error);
            }
        };
        Test();
    }, []);

    let json = {};
    if (apiResponse && apiResponseCost) {
        const accountingArray = [];
        apiResponse.forEach((item) => {
            accountingArray.push({
                label: item.name,
                value: item.id
            });
        });
        const costBearerArray = [];
        apiResponseCost.forEach((item) => {
            costBearerArray.push({
                label: item.name,
                value: item.id
            });
        });
        json = {
            accounting: accountingArray,
            costBearer: costBearerArray
        };
    }



//IN RETURN

 <Row>

   // EXAMPLE OF USE OF CUSTOM INPUT

    <CustomInput model={OrganizationModel} attribute='id' data={''} customChange={(e) => handleChange(e, 'id')}/>
    <CustomInput model={OrganizationModel} attribute='name' data={''} customChange={(e) => handleChange(e, 'name')}/>

    // EXAMPLE OF USE OF CUSTOM INPUT SELECT WITH CALL API
    {apiResponseAccounting && apiResponseCost ?
        <CustomInput model={RequestReimbursementFixed} attribute='costBearerId' data={''} customChange={(e) => handleChangeGlobal(e, 'costBearerId')} customOptions={json}/>
        : ''}

   //EXAMPLE OF USE OF CUSTOM INPUT WITH MAP

    {[...Array(numAddresses)].map((_, index) => (
        <Col lg={6} key={index} className='p-2'>

            <div className='border-green-default rounded-4 p-4 '>
                {index !== 0 ?
                    <CustomButton text='copy Address' size='w-25 mb-3' onClick={()=>copyAddress(index)}/>
                    : ''}

            <CustomInput model={AddressModel} attribute='type' data={''} customChange={(e) => handleChangeAddress(e, 'type', index)}/>
            <CustomInput model={AddressModel} attribute='address' data={''} customChange={(e) => handleChangeAddress(e, 'address', index)}/>
            <CustomInput model={AddressModel} attribute='city' data={''} customChange={(e) => handleChangeAddress(e, 'city', index)}/>
           </div>
        </Col>
    ))}
</Row>

//********* To use reactSelect , in the handleChange you have to put the value as value = e.value, and not the value= e.target.value , which it uses by default ******/




export function CustomInput({model, attribute, data, customChange = null, customOptions = null, fileId = null, fileExtension = null, fileName = null, wrongFileFormat = null, customField = null, setIsDeleted = null, validateAttribute = null, minDate = null,  maxDate = null, isInternalTranssaction = null}) {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const [value, setValue] = useState('');
    const [editableValue , setEditableValue] = useState(''); // no puede ser null da error, puede ser undefined en su defecto
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [displayModal, setDisplayModal] = useState(false);

    //const [attributeDetails, setAttributeDetails] = useState('');
   // useEffect(() => {
        const getAttributeDetails = (attr) => {
            if (model && typeof model.getAttributes === 'function') {
                return model.getAttributes(attr, null, customOptions ? customOptions : null, customField !== null ? customField : null );
            }
            return null;
        };
        // Retrieve the attribute details
        const attributeDetails = getAttributeDetails(attribute);
       // setAttributeDetails(attributeDetails);
        //setValue(attributeDetails[attribute].value(attribute));

    //}, [model , attribute]);


    useEffect(() => {
        setValue(data);
        setEditableValue(data);

        if(data === ' ') {
            setEditableValue('');
        }
    }, [data]);
    // test date for component setDateError
    // const[checkDateError, setCheckDateError] = useState(false);
    // if (checkDateError|| state[0].endDate === null) {
    //     errorState('Field required')
    // } else{
    //     errorState('')
    // }



    // const[dateValue, setDateValue] = useState(null); //new Date()
    // if (attributeDetails) {
    //     if(attributeDetails[attribute]?.form?.type === "date") {
    //         const handleDateValue = (date) => {
    //             setDateValue(date);
    //         }
    //     }
    // }

    let validation = (event) => {
        setEditableValue(event?.target?.value);
    };
    if (attributeDetails) {

         if (attributeDetails[attribute]?.form?.type === "phone") {
             validation = (response) =>{
                 setEditableValue(response);
             }
         }
        // if (attributeDetails[attribute]?.form?.type === "select" && attributeDetails[attribute]?.form?.search === true &&  attributeDetails[attribute]?.form?.validation) {
        //     const validationSchema = attributeDetails[attribute]?.form?.validation;
        //     validation = (e) => {
        //         setEditableValue(e?.value);
        //         validationSchema
        //             .validate({ [attribute]: selectedOption ? selectedOption.value : '' }) //{ [attribute]: event?.target?.value }
        //             .then(() => setError(''))
        //             .catch((validationError) => setError(validationError.errors[0]));
        //     }
        // }


        if (attributeDetails[attribute]?.form?.validation) {
            const validationSchema = attributeDetails[attribute]?.form?.validation;

            validation = (event) => {
                if(attributeDetails[attribute]?.form?.search === true) {
                    if ( event?.value) {
                        setEditableValue(event?.value);
                    }
                    // Validar con Yup
                    validationSchema
                        .validate( editableValue ? editableValue : event?.value)
                        .then(() => setError(''))
                        .catch((validationError) => setError(validationError.errors[0]));
                } else {
                    setEditableValue(event?.target?.value);
                    // Validar con Yup
                    validationSchema
                        .validate(event?.target?.value ) //{ [attribute]: event?.target?.value }
                        .then(() => setError(''))
                        .catch((validationError) => setError(validationError.errors[0]));
                }


            };
        }
        // if (attributeDetails[attribute]?.form?.validation && attributeDetails[attribute]?.form?.type === 'dateRangePicker' ) {
        //
        // }
        if (attributeDetails[attribute]?.form?.validation && attributeDetails[attribute]?.form?.type === 'file' ) {

        }

    }

     let validationBool = (boolValue) => {
         setEditableValue(!boolValue);
     };


    //DROPZONE OPTIONS

    const [validateFile, setValidateFile] = useState(false);
    const [showTooltipError, setShowTooltipError] = useState(false);
    const [responseDeleteFile, setResponseDeleteFile] = useState(null)

    const {acceptedFiles, getRootProps, getInputProps, isDragActive,
        isDragAccept, isDragReject} = useDropzone(
        {
            onDrop: (acceptedFiles) => {
                // Aquí puedes realizar cualquier acción que desees con los archivos aceptados
                customChange(acceptedFiles);
                // const validationSchema = attributeDetails[attribute]?.form?.validation;
                // acceptedFiles.forEach(file => {
                //     validationSchema.validate(file)
                //         .then(() => setError(''))
                //         .catch((validationError) => setError(validationError.errors[0]));
                // });
                // validation = (acceptedFiles) => {
                //     setEditableValue(acceptedFiles);
                //     // Validar con Yup
                //     validationSchema
                //         .validate(event?.target?.value ) //{ [attribute]: event?.target?.value }
                //         .then(() => setError(''))
                //         .catch((validationError) => setError(validationError.errors[0]));
                // };


            },
            accept: {
                'image/*': ['.jpeg', '.png','.gif','.jpg'],
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/vnd.ms-excel': ['.xls'],
                'text/plain': ['.txt'],
            },
            multiple: false,
            //maxFiles:1
            //onError:
        }
    );

    useEffect(() => {     
        
        if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0){
            
            if(validateAttribute !== null && validateAttribute === true) {
                setValidateFile(false);
                setShowTooltipError(false);
                
            }else{
                setValidateFile(true)
        }
        } else {
            //setValidateFile(false)
        }
    }, [acceptedFiles, validateAttribute]);

    /*useEffect(() => {
        if (responseDeleteFile !== null && responseDeleteFile ===  t('fileDeleted')) {
            setValidateFile(false);
            setShowTooltipError(false);
            setResponseDeleteFile(null);
            setFiles("");
        }
    }, [responseDeleteFile]);*/

    const [fileCopied, setFileCopied] = useState(null);

    useEffect(() => {
        if (fileId != null) {
            setFileCopied(fileId);
        } else {
            setFileCopied(null);
        }
    }, [fileId]);

    const DeleteFile = async () => {
        const apiCallOptions = {
            method: "delete",
            url: `/file?id=${fileId}`,
        };
        try {
            await ApiCall(apiCallOptions, setResponseDeleteFile);
            setValidateFile(false);
            setFileCopied(null);
        } catch (error) {
            // console.log(error);
        }
    }

    const [files, setFiles] = useState('');

    useEffect(() => {
        if (fileCopied !== null /*&& responseDeleteFile === null*/) {
            setFiles(<div className=" border-2 rounded bg-white mt-3" id="file-previews">
                <div className="p-4 m-0">
                    <Row>
                        <Col lg={12} className="d-flex justify-content-between">
                            <CustomButton text="" icon='bx bx-trash-alt pb-1' iconColor='#EB5757' iconSize={6}
                                          buttonColor='#e9cbcd' buttonRadius={3} hoverButtonRadius={3} borderColor={'white'}
                                          hoverIconColor='white' hoverButtonColor='#EB5757' customClasses="border-0"
                                          tooltipText={t('button.delete')}
                                          customClasses="btn-sm"
                                          onClick={() => {
                                              setDisplayModal(true);
                                          }}
                            />
                        </Col>
                        <Col lg={12} className="d-flex justify-content-center">
                            {/* IMAGE PREVIEW
                            <img src={`${API_URL}/file?id=${fileId}`} className={'img-fluid custom-size-img  align-items-center bg-white'}/>*/}
                            {fileExtension !== null ?
                                <a href={`${API_URL}/file?id=${fileCopied}`} target="_blank" className="text-decoration-none text-green d-flex">
                                    {fileExtension.includes('image') || fileExtension.includes('.png') || fileExtension.includes('.jpg') || fileExtension.includes('.jpeg') || fileExtension.includes('.gif') ? <i className="bx bx-image-alt fs-2"/> : ""}
                                    {fileExtension.includes('pdf') || fileExtension.includes('.pdf') ? <i className="bx bxs-file-pdf fs-2"/> : ""}
                                    {fileExtension.includes("wordprocessingml") || fileExtension.includes("msword") || fileExtension.includes('.doc') || fileExtension.includes(".docx") ? <i className="bx bxs-file-doc fs-2"/> : ""}
                                    {fileExtension.includes("text/plain") || fileExtension.includes(".txt") ? <i className="bx bxs-file-txt fs-2"/> : ""}
                                    {fileExtension.includes("sheet") || fileExtension.includes("excel") || fileExtension.includes(".xlsx") || fileExtension.includes(".xls") ? <i className="bx bxs-file fs-2"/> : ""}
                                    <span className='align-self-center ps-2'>{fileName !== null ? (fileName.length > 40 ? fileName.substring(0, 40) + "..." : fileName) : ""}</span>

                                </a>
                            : ""}
                        </Col>
                    </Row>
                </div>
            </div>);
        } else {
            setFiles("");
        }
    }, [fileCopied]);

    const contentBodyDelete = (
        <div>{t('validationMessage.modalImage')}</div>
    );
    const contentFooterDelete = (
        <>
            <Button className="box-green border-0"
                    onClick={() => {
                        setDisplayModal(false); // Close Modal
                        if (setIsDeleted != null) {
                            setIsDeleted(true); // Set isDeleted to true
                        }
                        DeleteFile();
                    }}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayModal(false)}>{t('button.cancel')}</Button>
        </>
    );


    //get the index of the reactSelect options

    let indexSelect = null;
        if (attributeDetails) {
            if (attributeDetails[attribute]?.form?.type === 'select' && attributeDetails[attribute]?.form?.search === true && editableValue) {
                indexSelect = attributeDetails[attribute]?.form?.options.findIndex((option) => option.value === editableValue);

            }
        }



    setTimeout(()=>{
        $('.react-datepicker-wrapper').addClass('w-100');
        $('.react-datepicker__input-container').addClass('w-100');
    },80)

    setTimeout(() => {
        $(".react-datepicker__input-container").find('input').prop("readonly", true);
    }, 200);

    // useEffect(() => {
    //     if( attributeDetails[attribute]?.form?.type === 'datetime') {
    //         $(`#${attributeDetails[attribute]?.id}`).blur();
    //     }
    //
    // }, [editableValue]);
    const handlePredefinedDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + 14);  // Sumar 14 días a la fecha actual
        return today;
    };

    const handleChangeDatePicker = (dates) => {
        const [newStartDate, newEndDate] = dates;
        setStartDate(newStartDate);
        setEndDate(newEndDate);

        if (!newStartDate && !newEndDate) {
            setStartDate(null);
            setEndDate(null);
        }
    }

    const customLocale = CustomDateLocale();

    return (
        <>
            {attributeDetails ?


            <Col lg={/*attributeDetails[attribute]?.form?.type === 'dateRangePicker' && attributeDetails[attribute]?.form?.validation ? 3 :*/ attributeDetails[attribute]?.form?.colSizeInput ?? 12} className="position-relative">
                <CustomModal isOpen={displayModal} toggle={() => setDisplayModal(!displayModal)} body={contentBodyDelete} footer={contentFooterDelete}/>
                <FormGroup className={` text-start ${!attributeDetails[attribute]?.label ? 'mb-md-2' : '' } ${attributeDetails[attribute]?.form?.type === 'switch' ? 'form-switch' :
                    attributeDetails[attribute]?.form?.inputType === 'inline' ? 'd-flex align-items-center'
                        : ''  }`} floating={attributeDetails[attribute]?.form?.inputType === 'inside'} >
                    {
                        attributeDetails[attribute]?.form?.inputType === 'inside' ? ''
                            :
                            attributeDetails[attribute]?.form?.type === 'checkbox' || attributeDetails[attribute]?.form?.type === 'radio' || attributeDetails[attribute]?.form?.type === 'switch' ?
                                <Label className={ attributeDetails[attribute]?.form?.type === 'switch' ? 'form-check-label':'' }>{attributeDetails[attribute]?.label ? attributeDetails[attribute]?.label : ''}</Label>
                                :
                                <div><Label className= { attributeDetails[attribute]?.form?.inputType === 'inline' ? ' mb-0 w-form-label' : 'd-block' }>{attributeDetails[attribute]?.label ? `${attributeDetails[attribute]?.label}:` : ''}{attributeDetails[attribute]?.form?.mandatory === 'mandatory' ?
                                    <span style={{ marginLeft: '10px', color: 'red' }}>*</span>
                                     : ''}</Label>
                                     {attributeDetails[attribute]?.form?.infoText && (
                                        <div className="text-green d-flex align-items-center mb-3 ms-2">
                                            <i className="bx bx-info-circle fs-5 pe-2" />
                                            {attributeDetails[attribute]?.form?.infoText}
                                        </div>
                                     )}
                                </div>  
                    }

                    {attributeDetails[attribute]?.form?.type === 'text' ?
                        <Input
                            className='flex-grow-1'
                            id={attributeDetails[attribute]?.id}
                            name={attributeDetails[attribute]?.name}
                            bsSize={attributeDetails[attribute]?.form?.inputSize === 'long' ? 'lg' :
                                attributeDetails[attribute]?.form?.inputSize === 'small' ? 'sm'
                                    :''}
                            type={attributeDetails[attribute]?.form?.type}
                            placeholder={attributeDetails[attribute]?.form?.placeholder}
                             value={editableValue}
                             onBlur={(e) =>  {
                                 validation(e);
                             }}
                             onChange={(e) => {
                                 validation(e);
                                 if (typeof customChange === 'function') {
                                     customChange(e);
                                 }

                             }}
                        />
                        : attributeDetails[attribute]?.form?.type === 'select' ?
                            (attributeDetails[attribute]?.form?.search === true /*&& indexSelect !== null*/ ?
                                //SelectSearch()
                                <Select
                                    className='border rounded border-custom'
                                    options={attributeDetails[attribute]?.form?.options}
                                    value={indexSelect !== null && indexSelect !==  -1 ? attributeDetails[attribute]?.form?.options[indexSelect] : ''}
                                    styles={{ control: (styles, { isFocused }) => ({
                                           ...styles,
                                           border: isFocused ? '1px solid #67a58f !important' : "none",
                                           boxShadow:isFocused ? '0 0 0 0.25rem rgba(21,117,83,.25) !important' :'none',
                                       }),
                                    }}
                                    placeholder={attributeDetails[attribute]?.form?.placeholder}
                                    onBlur={(e) =>  validation(e)}
                                    onChange={(e) => {
                                        //handleChange(e);
                                        validation(e);
                                        if (typeof customChange === 'function') {
                                            customChange(e);
                                        }
                                    }}
                                    // CUSTOM FILTER FOR SELECT SEARCH
                                    filterOption={(option, inputValue) => option.label.toLowerCase().includes(inputValue.toLowerCase())}
                               />

                                :
                                    <Input
                                        id={attributeDetails[attribute]?.id}
                                        name={attributeDetails[attribute]?.name}
                                        bsSize={attributeDetails[attribute]?.form?.inputSize === 'long' ? 'lg' :
                                            attributeDetails[attribute]?.form?.inputSize === 'small' ? 'sm'
                                                :''}
                                        type={attributeDetails[attribute]?.form?.type}
                                        defaultValue={editableValue !== '' ? editableValue : ''}
                                        value={editableValue}
                                         onBlur={(e) =>  validation(e)}
                                         onChange={(e) => {
                                            //handleChange(e);
                                            validation(e);
                                            if (typeof customChange === 'function') {
                                                customChange(e);
                                            }
                                        }}
                                    >
                                        <option disabled={true} value=''>{t('formPlaceholder.selectOption')}</option>
                                        {attributeDetails[attribute]?.form.options.map((value, index) =>(
                                            <option key={value+index} value={value.value}>{value.label}</option>
                                        ))}

                                    </Input>)

                                    : attributeDetails[attribute]?.form?.type === 'phone' ?
                                        <CustomPhoneInput
                                            value={editableValue}
                                            typeImport = { attributeDetails[attribute]?.form?.inputType === 'inside' ? 'bootstrap' : 'plain'}
                                            //style={'text-start mb-4 z-2'}
                                             onChange={async response => {
                                                 try {
                                                     setEditableValue(response);
                                                     const validationSchema = attributeDetails[attribute]?.form?.validation;
                                                     await validationSchema.validate( response ); //{ [attribute]: response }
                                                     setError('');
                                                 } catch (validationError) {
                                                     setError(validationError.errors[0]);
                                                 }
                                                 if (typeof customChange === 'function') {
                                                     customChange(response);
                                                 }
                                             }}
                                        />
                                        : attributeDetails[attribute]?.form?.type === 'textarea' ?
                                            <Input
                                                className='flex-grow-1'
                                                id={attributeDetails[attribute]?.id}
                                                name={attributeDetails[attribute]?.name}
                                                bsSize={attributeDetails[attribute]?.form?.inputSize === 'long' ? 'lg' :
                                                    attributeDetails[attribute]?.form?.inputSize === 'small' ? 'sm'
                                                        :''}
                                                type={attributeDetails[attribute]?.form?.type}
                                                rows={attributeDetails[attribute]?.form?.rows}
                                                placeholder={attributeDetails[attribute]?.form?.placeholder}
                                                value={editableValue}
                                                  onBlur={(e) =>  validation(e)}
                                                  onChange={(e) => {
                                                      validation(e);
                                                      if (typeof customChange === 'function') {
                                                          customChange(e);
                                                      }
                                                  }}
                                            />
                                            : attributeDetails[attribute]?.form?.type === 'file' || attributeDetails[attribute]?.form?.type === 'File' ?
                                                <>
                                                    {files === "" ?
                                                        <div id={`divFile${attributeDetails[attribute]?.id}`} onClick={() => setShowTooltipError(true)} >
                                                            <div {...getRootProps({className: attribute?.inputClass || 'dropzone position-relative'})} >
                                                                <input id={attributeDetails[attribute]?.id} {...getInputProps()}  /*onChange={(e) => customChange(e) }*//>
                                                                <div className="mb-3 mt-5 text-center">
                                                                    {attributeDetails[attribute]?.form?.iconView === false ?
                                                                        ''
                                                                        : <i className={attributeDetails[attribute]?.form?.icon || 'display-4 text-muted bx bx-cloud-upload'}/>
                                                                    }
                                                                    {attributeDetails[attribute]?.form?.placeholder ?
                                                                        <>
                                                                            {!isDragActive && (<p>{attributeDetails[attribute]?.form?.placeholder}</p>)}
                                                                            {isDragAccept && (<p className='text-success fw-bold'>{t('validationMessage.fileFormatCorrect')}</p>)}
                                                                            {isDragReject && (<p className='text-danger fw-bold'>{t('validationMessage.fileFormatIncorrect')}</p>)}
                                                                        </>
                                                                        :
                                                                        ''}

                                                                </div>
                                                            </div>

                                                        </div>
                                                    : ""}
                                                    {files}
                                                        {
                                                            attributeDetails[attribute]?.form?.buttonView === false ?
                                                                ''
                                                                :
                                                                <div className="text-center mt-4">
                                                                    <button
                                                                        type={attributeDetails[attribute]?.form?.buttonType || 'button'}
                                                                        className={'btn button-bg-green text-white'}>   {t('button.send')}
                                                                        {t('files')}
                                                                    </button>
                                                                </div>

                                                        }

                                                        </>
                                                        : attributeDetails[attribute]?.form?.type === 'radio' ?
                                                        <ul className={`p-0 list-unstyled ${ attributeDetails[attribute]?.form?.classRadio === 'inLine' ? 'd-flex' : ''}`}>
                                                        {attributeDetails[attribute]?.form.options.map((value, index) =>(
                                                            <li key={value+index} className={attributeDetails[attribute]?.form?.classRadio === 'inLine' ? 'pe-5' : ''}>
                                                                <Label className='d-inline ps-2'>{value.label}
                                                                <Input
                                                                    className='flex-grow-1 float-start'
                                                                    id={attributeDetails[attribute]?.id}
                                                                    name={attributeDetails[attribute]?.name}
                                                                    bsSize={attributeDetails[attribute]?.form?.inputSize === 'long' ? 'lg' :
                                                                        attributeDetails[attribute]?.form?.inputSize === 'small' ? 'sm'
                                                                            :''}
                                                                    type={attributeDetails[attribute]?.form?.type}
                                                                    value={value.value} //editableValue
                                                                    //checked
                                                                    onBlur={(e) =>  {
                                                                        validation(e);
                                                                    }}
                                                                    onChange={(e) => {
                                                                        validation(e);
                                                                        if (typeof customChange === 'function') {
                                                                            customChange(e);
                                                                        }

                                                                    }}
                                                                />
                                                                </Label>
                                                            </li>
                                                        ))}

                                                    </ul>

                                                    : attributeDetails[attribute]?.form?.type === 'checkbox' || attributeDetails[attribute]?.form?.type === 'switch' ?
                                                        <Input
                                                            className={'form-check-input mx-2 float-start'}
                                                            id={attributeDetails[attribute]?.id}
                                                            name={attributeDetails[attribute]?.name}
                                                            bsSize={attributeDetails[attribute]?.form?.inputSize === 'long' ? 'lg' :
                                                                attributeDetails[attribute]?.form?.inputSize === 'small' ? 'sm'
                                                                    :''}
                                                            type= 'checkbox'
                                                            checked={editableValue}
                                                            onBlur={(e) =>  validation(e)}
                                                            onChange={(e) => {
                                                                validationBool(editableValue);
                                                                if (typeof customChange === 'function') {
                                                                    customChange(e);
                                                                }
                                                            }}
                                                        />
                                                        : attributeDetails[attribute]?.form?.type === 'password' &&  attributeDetails[attribute]?.form?.inputType !== 'inside'?
                                                            <>
                                                                <div className={attributeDetails[attribute]?.form?.type === 'password' ? 'position-relative w-100' : ''}>
                                                                    {/*: attributeDetails[attribute]?.form?.inputType === 'inline' ? 'flex-grow-1'*/}
                                                                    <Input
                                                                        className={attributeDetails[attribute]?.form?.type === 'color' ? 'form-control-color d-inline-flex' :''}
                                                                        id={attributeDetails[attribute]?.id}
                                                                        name={attributeDetails[attribute]?.name}
                                                                        bsSize={attributeDetails[attribute]?.form?.inputSize === 'long' ? 'lg' :
                                                                            attributeDetails[attribute]?.form?.inputSize === 'small' ? 'sm'
                                                                                :''}
                                                                        type={attributeDetails[attribute]?.form?.type === 'password' ?(showPassword ? 'text' : 'password') : attributeDetails[attribute]?.form?.type}
                                                                        placeholder={attributeDetails[attribute]?.form?.placeholder}
                                                                        value={editableValue}
                                                                        onBlur={(e) =>  validation(e)}
                                                                        onChange={(e) => {
                                                                            validation(e);
                                                                            if (typeof customChange === 'function') {
                                                                                customChange(e);
                                                                            }
                                                                        }}
                                                                    />
                                                                    {attributeDetails[attribute]?.form?.type === 'password' ? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-link position-absolute h-100 end-0 top-0"
                                                                            id="password-addon"
                                                                            onClick={() => {
                                                                                setShowPassword(!showPassword);
                                                                            }}
                                                                        >
                                                                            <i className={`${!showPassword ? 'far fa-eye ' : 'far fa-eye-slash'} font-size-18 text-muted`}></i>
                                                                        </button>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                            </>

                                                                : attributeDetails[attribute]?.form?.type === 'dateRangePicker' ?
                                                                      // <DateRangePicker start={''} end={''} errorState={setError} required={attributeDetails[attribute]?.form?.validation}/>
                                                                    <DatePicker
                                                                        id={attributeDetails[attribute]?.id}
                                                                        selectsRange={true}
                                                                        startDate={startDate }
                                                                        endDate={endDate}
                                                                        onBlur={(e) =>  validation(e)}
                                                                        onChange={(e) => {
                                                                            handleChangeDatePicker(e);
                                                                            setTimeout(() => {
                                                                                $(`#${attributeDetails[attribute]?.id}`).blur();
                                                                            }, 80);
                                                                           // setEditableValue(e);
                                                                            if (typeof customChange === 'function') {
                                                                                customChange(e);
                                                                            }
                                                                        }}
                                                                        locale={customLocale}
                                                                        dateFormat="yyyy-MM-dd"
                                                                        placeholderText={attributeDetails[attribute]?.form?.placeholder ? attributeDetails[attribute]?.form?.placeholder : t('select.date')}
                                                                        className="w-100 border-grey-date rounded-2 padding-input-date"

                                                                    />

                                                                    :attributeDetails[attribute]?.form?.type === 'date' ?
                                                                        <DatePicker
                                                                            id={attributeDetails[attribute]?.id}
                                                                            selected={editableValue}
                                                                            onBlur={(e) =>  validation(e)}
                                                                            onChange={(e) => {
                                                                                setEditableValue(e);
                                                                                if (typeof customChange === 'function') {
                                                                                    customChange(e);
                                                                                }
                                                                                setTimeout(() => {
                                                                                    $(`#${attributeDetails[attribute]?.id}`).blur();
                                                                                }, 80);

                                                                            }}
                                                                            locale={customLocale}
                                                                            dateFormat="yyyy-MM-dd"
                                                                            placeholderText={attributeDetails[attribute]?.form?.placeholder ? attributeDetails[attribute]?.form?.placeholder : t('select.date')}
                                                                            className="w-100 border-grey-date rounded-2 padding-input-date"
                                                                            maxDate={maxDate ? new Date(maxDate) : (attributeDetails[attribute]?.form?.maxDate ?? null)}
                                                                            minDate={minDate ? new Date(minDate) : (attributeDetails[attribute]?.form?.minDate ?? null)}
                                                                            filterDate={attributeDetails[attribute]?.form?.filterDate ?? null}
                                                                        />
                                                                        // <DatePicker
                                                                        //     // selectsRange={true}
                                                                        //     // startDate={startDate}
                                                                        //     // endDate={endDate}
                                                                        //     // onChange={(update) => {
                                                                        //     //     handleChange(update);
                                                                        //     // }}
                                                                        //     isClearable
                                                                        //     showMonthDropdown
                                                                        //     showYearDropdown
                                                                        //     dropdownMode="select"
                                                                        //     dateFormat="yyyy-MM-dd"
                                                                        //     placeholderText="from date - to date"
                                                                        //     className="border border-1  rounded "
                                                                        // />
                                                                    : attributeDetails[attribute]?.form?.type === 'datetime' ?
                                                                        <DatePicker
                                                                            id={attributeDetails[attribute]?.id}
                                                                            selected={editableValue}
                                                                            onBlur={(e) =>  validation(e)}
                                                                            onChange={(e) => {
                                                                                setEditableValue(e);
                                                                                if (typeof customChange === 'function') {
                                                                                    customChange(e);
                                                                                }
                                                                                setTimeout(() => {
                                                                                    $(`#${attributeDetails[attribute]?.id}`).blur();
                                                                                }, 80);
                                                                            }}
                                                                            locale={customLocale}
                                                                            showTimeSelect
                                                                            timeIntervals={60}
                                                                            timeFormat="HH:mm"
                                                                            dateFormat="yyyy-MM-dd h:mm aa"
                                                                            placeholderText={t('select.date')}
                                                                            className="w-100 border-grey-date rounded-2 padding-input-date"
                                                                        />
                                                                    :
                                                                    attributeDetails[attribute]?.form?.type === 'datePredefined' ? (
                                                                        <DatePicker
                                                                            id={attributeDetails[attribute]?.id}
                                                                            selected={editableValue || handlePredefinedDate()}  // Preselecciona la fecha de hoy + 14 días
                                                                            onBlur={(e) => validation(e)}
                                                                            onChange={(e) => {
                                                                                setEditableValue(e);
                                                                                if (typeof customChange === 'function') {
                                                                                    customChange(e);
                                                                                }
                                                                                setTimeout(() => {
                                                                                    $(`#${attributeDetails[attribute]?.id}`).blur();
                                                                                }, 80);
                                                                            }}
                                                                            locale={customLocale}
                                                                            dateFormat="yyyy-MM-dd"
                                                                            placeholderText={attributeDetails[attribute]?.form?.placeholder || t('select.date')}
                                                                            className="w-100 border-grey-date rounded-2 padding-input-date"
                                                                            maxDate={attributeDetails[attribute]?.form?.maxDate ?? null}
                                                                            minDate={attributeDetails[attribute]?.form?.minDate ?? null}
                                                                            filterDate={attributeDetails[attribute]?.form?.filterDate ?? null}
                                                                        />
                                                                    ) : 
                                                                    attributeDetails[attribute]?.form?.type === 'number' ?
                                                                       isInternalTranssaction == null ?
                                                                        <NumberInput id={attributeDetails[attribute]?.id} name={attributeDetails[attribute]?.name} value={editableValue} placeholder={attributeDetails[attribute]?.form?.placeholder} inputSize={attributeDetails[attribute]?.form?.inputSize} validation={validation} customOnChange={customChange} oneDecimal={attributeDetails[attribute]?.form?.oneDecimal ?? null} possibleDecimalValue={attributeDetails[attribute]?.form?.possibleDecimalValue ?? null} />
                                                                        :
                                                                        <NumberInputInternal id={attributeDetails[attribute]?.id} name={attributeDetails[attribute]?.name} value={editableValue} placeholder={attributeDetails[attribute]?.form?.placeholder} inputSize={attributeDetails[attribute]?.form?.inputSize} validation={validation} customOnChange={customChange} oneDecimal={attributeDetails[attribute]?.form?.oneDecimal ?? null} possibleDecimalValue={attributeDetails[attribute]?.form?.possibleDecimalValue ?? null} />
                                                                    :
                                                                    <>
                                                                        {/*default input*/}
                                                                        <Input
                                                                            className={ attributeDetails[attribute]?.form?.type === 'color' ? 'form-control-color d-inline-flex' :''}
                                                                            id={attributeDetails[attribute]?.id}
                                                                            name={attributeDetails[attribute]?.name}
                                                                            bsSize={attributeDetails[attribute]?.form?.inputSize === 'long' ? 'lg' :
                                                                                attributeDetails[attribute]?.form?.inputSize === 'small' ? 'sm'
                                                                                    :''}
                                                                            type={attributeDetails[attribute]?.form?.type === 'password' ?(showPassword ? 'text' : 'password') : attributeDetails[attribute]?.form?.type}
                                                                            placeholder={attributeDetails[attribute]?.form?.placeholder}
                                                                            value={editableValue}
                                                                            onBlur={(e) =>  validation(e)}
                                                                            onChange={(e) => {
                                                                                validation(e);
                                                                                if (typeof customChange === 'function') {
                                                                                    customChange(e);
                                                                                }
                                                                            }}
                                                                        />
                                                                        {attributeDetails[attribute]?.form?.type === 'password' ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-link position-absolute h-100 end-0 top-0"
                                                                                id="password-addon"
                                                                                onClick={() => {
                                                                                    setShowPassword(!showPassword);
                                                                                }}
                                                                            >
                                                                                <i className={`${!showPassword ? 'far fa-eye ' : 'far fa-eye-slash'} font-size-18 text-muted pe-3`}></i>
                                                                            </button>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </>
                                                                }
                    { attributeDetails[attribute]?.form?.type === 'phone' ? '' :
                        attributeDetails[attribute]?.form?.inputType === 'inside' ?
                            <Label for={attributeDetails[attribute]?.id} className='bg-transparent'>{attributeDetails[attribute]?.label ? attributeDetails[attribute]?.label : ''}</Label>
                            : ''
                    }
                    {
                        attributeDetails[attribute]?.form?.inputType === 'inside' ?
                            <div className={`form-floating-icon ${attributeDetails[attribute]?.form?.type === 'textarea' ? 'align-items-start pt-3' : ''}`}>
                                <i className={attributeDetails[attribute]?.form?.inputIcon ?? ''} />
                            </div>
                            : ''
                    }
                </FormGroup>
                {error && <div className={`error ${ attributeDetails[attribute]?.form?.inputType === 'inside' ? 'tooltip-custom' :
                    attributeDetails[attribute]?.form?.inputType === 'inline' ? 'tooltip-custom2'
                        : !attributeDetails[attribute]?.label ? 'tooltip-custom2'
                            : 'tooltip-custom-normal'} show-custom`} style={{ color: '#f56e6e' }}>{error}</div>}
                {attributeDetails[attribute]?.form?.type === 'file' && validateFile === false || attributeDetails[attribute]?.form?.type === 'File' && validateFile === false
                    ?
                    <div className={`error ${ attributeDetails[attribute]?.form?.inputType === 'inside' ? 'tooltip-custom' :
                    attributeDetails[attribute]?.form?.inputType === 'inline' ? 'tooltip-custom2'
                        : !attributeDetails[attribute]?.label ? 'tooltip-custom2'
                        : 'tooltip-custom-normal'} show-custom ${validateFile === false  && showTooltipError ? 'opacity-1' :'opacity-0' }`} style={{ color: '#f56e6e' }}>{t('validationMessage.fieldRequired')}</div>
                    :''
                }
                {wrongFileFormat != null && wrongFileFormat === true && <div className={`error tooltip-custom-normal show-custom`} style={{ color: '#f56e6e' }}>{t('validationMessage.fileFormatIncorrect')}</div>}
            </Col>
            : ''}
        </>

    );



}


