import {Input} from "reactstrap";

export const NumberInput = ({ name, value, id = null, placeholder = null, inputSize = null, validation, customOnChange = null, oneDecimal = null, possibleDecimalValue = null }) => {
    let visualValue = "";
    if (value) {
        visualValue = value.toString().replace(".", ",");
    }
    let inputSizeValue = "";
    if (inputSize !== null) {
        inputSizeValue = inputSize === 'long' ? 'lg' : 'sm';
    }
    // ON INPUT FUNCTION
    const onInput = (e) => {
        if (oneDecimal !== null && oneDecimal === true) {
            e.target.value = e.target.value.match(/^\d+\,?\d{0,1}/);
        } else if (oneDecimal !== null && oneDecimal === false) {
            e.target.value = e.target.value.match(/^\d+/);
        } else {
            e.target.value = e.target.value.match(/^\d+\,?\d{0,2}/);
        }
        if (possibleDecimalValue !== null) {
            if (e.target.value.includes(",")) {
                const lastLetter = e.target.value.charAt(e.target.value.length - 1);
                if (lastLetter !== "0" && lastLetter !== "," && lastLetter !== possibleDecimalValue.toString()) {
                    e.target.value = e.target.value.slice(0, -2);
                }
            }
        }
    }

    // ON CHANGE FUNCTION
    const onChange = (e) => {
        validation(e);
        if (customOnChange !== null && typeof customOnChange === 'function') {
            customOnChange(e);
        }
    }

    return (
        <Input className='flex-grow-1' id={id} name={name} placeholder={placeholder} type="text" bsSize={inputSizeValue} value={visualValue}
               onBlur={(e) => validation(e)}
               onInput={(e) => onInput(e)}
               onChange={(e) => onChange(e)}
        />
    );
}
export const NumberInputInternal = ({name, value, id = null, placeholder = null, inputSize = null, validation, customOnChange = null, oneDecimal = null, possibleDecimalValue = null,}) => {
    let visualValue = "";
    if (value) {
        visualValue = value.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    let inputSizeValue = "";
    if (inputSize !== null) {
        inputSizeValue = inputSize === "long" ? "lg" : "sm";
    }
    // ON INPUT FUNCTION
    const onInput = (e) => {
        let rawValue = e.target.value.replace(/\s/g, "");
        rawValue = rawValue.replace(",", ".");

        if (oneDecimal === true) {
            rawValue = rawValue.match(/^\d+(\.\d{0,1})?/);
        } else if (oneDecimal === false) {
            rawValue = rawValue.match(/^\d+/);
        } else {
            rawValue = rawValue.match(/^\d+(\.\d{0,2})?/);
        }

        if (rawValue) {
            rawValue = rawValue[0];
        }

        if (possibleDecimalValue !== null && rawValue && rawValue.includes(".")) {
            const lastChar = rawValue.slice(-1);
            if (
                lastChar !== "0" &&
                lastChar !== "." &&
                lastChar !== possibleDecimalValue.toString()
            ) {
                rawValue = rawValue.slice(0, -1);
            }
        }

        e.target.value = rawValue
            ? rawValue.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "";
    };

    // ON CHANGE FUNCTION
    const onChange = (e) => {
        let rawValue = e.target.value.replace(/\s/g, "").replace(",", ".");
        validation({ target: { value: rawValue, name: e.target.name } });

        e.target.value = rawValue.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        if (customOnChange !== null && typeof customOnChange === "function") {
            customOnChange({ target: { value: rawValue, name: e.target.name } });
        }
    };

    return (
        <Input className="flex-grow-1" id={id} name={name} placeholder={placeholder} type="text"  bsSize={inputSizeValue} value={visualValue}
            onBlur={(e) => validation(e)}
            onInput={(e) => onInput(e)}
            onChange={(e) => onChange(e)}
        />
    );
};