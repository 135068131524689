import { useTranslation } from "react-i18next";
import useApiCallService from "../../Common/Helpers/ApiCall";
import { CustomReload } from "../../AppSystem/Helpers/CustomReload";
import { CustomButton } from "../../Common/Components/Button/CustomButton";
import ResponseSalarySheetModel from "../../SalarySheet/Models/ResponseSalarySheetModel";
import { Col } from "reactstrap";
import { InfoPageMessage } from "../../Common/Components/Button/InfoPageMessage";
import SortableList from "../../Common/Components/Lists/SortableList";
import ResponseEconomicReportModel from "../Models/ResponseEconomicReportModel";

const AdminEconomicReport = () => {
    const defaultStartDate = new Date();
    defaultStartDate.setHours(0);
    defaultStartDate.setMinutes(1);
    defaultStartDate.setSeconds(0);
    defaultStartDate.setMilliseconds(0);
    const defaultEndDate = new Date();
    defaultEndDate.setHours(23);
    defaultEndDate.setMinutes(59);
    defaultEndDate.setSeconds(59);
    defaultEndDate.setMilliseconds(0);
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
  
    const handleDownloadApiResponse  =  (response, name) => {
        let file;
        if (response instanceof Blob) {
          file = response;
          
        } else {
          file = new Blob([response]);
        }
        let fileName = name;

        if (!name || name.trim() === "") {
            fileName = "file.pdf";
        } else {
            fileName = name;
        }

        const urlToFile = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = urlToFile;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        setTimeout(() => {
          CustomReload();
        }, 2000);
    }

    const DownloadFile = async (id, fileName) => {
        let url = `/file?id=${id}`        
        const apiCallOptions = {
            method: "get",
            url: url,
            dataTypes: 'blob',
            responseType: 'blob'
        };
        try {
            await ApiCall(apiCallOptions, (response) => handleDownloadApiResponse(response, fileName));

        } catch (error) {
            console.log(error);
        }
    };
    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon="bx bx-down-arrow-alt pb-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText='Nedladdningar' customClasses="rounded" buttonBorder={false} customClassesTooltip="tooltip-custom-button-table"
                              id={item.id}
                              onClick={() => {
                              DownloadFile(item.fileId, item.fileName);
                            }}
                />
            </>
        );
    }

    const columns = ["createdAt",'costPlace','costBearerName','orgType','orgName','model','createdByName','amount'];

    const listOptions = {
        "model": ResponseEconomicReportModel,
        'columns': columns,
        "exportIsAvailable": true,
        "filterTestIsAvailable": true,
        "economicReportFilter": true,
        "urlGetListItemsToExport": "/export-data/admin/search-economic-report",
        "nameFile": t('sidebar.economicReport'),
        "ignoreFields" : [...columns],
        "urlGetSearch" : "/export-data/admin/search-economic-report",
    };
      return (
        <>        
          <Col lg="12" className='card card-backend p-4 mb-5'>
                {/* <h2 className="pb-2 rokkitt-font">{t('modelLabel.salarySheet')}</h2> */}
                <h2 className="pb-2 rokkitt-font">Economic Report</h2>
                <InfoPageMessage message={t('infoBox.salarySheet')}/>
                <SortableList
                    listOptions={listOptions}
                />
            </Col>
        </>
    );
};

export default AdminEconomicReport;